import {
  DocumentsPageLinkFragment,
  InformationListPageLinkFragment,
  InformationPageLinkFragment,
  PagePlugFragment,
} from '../dato';

export function isPagePlug(item: any): item is PagePlugFragment {
  return (
    Object.prototype.hasOwnProperty.call(item, '__typename')
    && item.__typename === 'PagePlugRecord'
  );
}

export function isInformationListPageLink(
  item: any,
): item is InformationListPageLinkFragment {
  return (
    Object.prototype.hasOwnProperty.call(item, '__typename')
    && item.__typename === 'InformationListPageRecord'
  );
}
export function isInformationPageLink(
  item: any,
): item is InformationPageLinkFragment {
  return (
    Object.prototype.hasOwnProperty.call(item, '__typename')
    && item.__typename === 'InformationPageRecord'
  );
}
export function isDocumentsPageLink(
  item: any,
): item is DocumentsPageLinkFragment {
  return (
    Object.prototype.hasOwnProperty.call(item, '__typename')
    && item.__typename === 'DocumentsPageRecord'
  );
}
