import { createContext, memo, useEffect, useState } from 'react';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { ApiSettings } from '@hultafors/shared/types';

import {
  FooterFragment,
  GlobalFields,
  GlobalFragment,
  LanguageSelectorFragment,
  MaintenanceModeFragment,
  MenuFragment,
  MenuItemFragment,
  TimedDialogFragment,
} from '@hultafors/wibe/types';

export interface GlobalContextValue extends GlobalFields {
  settings: ApiSettings;
  setAllMenuItems(value: MenuItemFragment[]): void;
  setFooter(value: FooterFragment): void;
  setGlobal(value: GlobalFragment): void;
  setLanguageSelector(value: LanguageSelectorFragment): void;
  setMaintenanceMode(value: MaintenanceModeFragment): void;
  setMenu(value: MenuFragment): void;
  setTimedDialog(value: TimedDialogFragment): void;
}

export const GlobalContext = createContext<GlobalContextValue | null>(null);

export interface GlobalProviderProps {
  children?: React.ReactNode;
  value: GlobalContextValue;
}

export const GlobalProvider: React.FC<GlobalProviderProps> = ({
  children,
  value,
}) => {
  const [global, setGlobal] = useState<GlobalFragment>(value.global);
  const [menu, setMenu] = useState<MenuFragment>(value.menu);
  const [allMenuItems, setAllMenuItems] = useState<MenuItemFragment[]>(
    value.allMenuItems,
  );
  const [footer, setFooter] = useState<FooterFragment>(value.footer);
  const [timedDialog, setTimedDialog] = useState<TimedDialogFragment>(
    value.timedDialog,
  );

  const [languageSelector, setLanguageSelector]
    = useState<LanguageSelectorFragment>(value.languageSelector);

  const [maintenanceMode, setMaintenanceMode]
    = useState<MaintenanceModeFragment>(value.maintenanceMode);

  const [appInsights, setAppInsights] = useState<ApplicationInsights>();
  useEffect(() => {
    if (!appInsights && process.env['APPINSIGHTS_INSTRUMENTATIONKEY']) {
      setAppInsights(
        new ApplicationInsights({
          config: {
            instrumentationKey: process.env['APPINSIGHTS_INSTRUMENTATIONKEY'],
          },
        }),
      );
    }
  }, [value.settings]);

  useEffect(() => {
    if (appInsights) {
      appInsights.loadAppInsights();
      appInsights.trackPageView();
    }
  }, [appInsights]);

  useEffect(() => {
    if (value.menu) {
      setMenu(value.menu);
    }
    if (value.allMenuItems) {
      setAllMenuItems(value.allMenuItems);
    }
    if (value.global) {
      setGlobal(value.global);
    }
    if (value.footer) {
      setFooter(value.footer);
    }
    if (value.timedDialog) {
      setTimedDialog(value.timedDialog);
    }
  }, [value]);

  return (
    <GlobalContext.Provider
      value={{
        ...value,
        footer,
        setFooter,
        allMenuItems,
        setAllMenuItems,
        global,
        setGlobal,
        menu,
        setMenu,
        timedDialog,
        setTimedDialog,
        languageSelector,
        setLanguageSelector,
        maintenanceMode,
        setMaintenanceMode,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const GlobalProviderMemo = memo(GlobalProvider);

export interface BackLink {
  title: string;
  urlPath: string;
}
