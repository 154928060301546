const LANDING_VIEW = '/';
const ABOUT = 'about';
const CAMPAIGN = 'campaign';
const CATEGORY = 'products';
const CONTACT = 'contact';
const DOCUMENTS = 'documents';
const EXPLORE = 'explore';
const FAQ = 'faq';
const GUIDES = 'guides';
const RETAILERS = 'retailers';
const SEARCH = 'search';

export const routes = {
  LANDING_VIEW,
  ABOUT,
  CAMPAIGN,
  CATEGORY,
  CONTACT,
  DOCUMENTS,
  EXPLORE,
  FAQ,
  GUIDES,
  RETAILERS,
  SEARCH,
};

export type Route = keyof typeof routes;
